
import { defineComponent, ref, reactive, onMounted } from 'vue';
import Multiselect from '@vueform/multiselect';
import InputNumber from 'primevue/inputnumber';
import { useRoute } from 'vue-router';
import router from '@/router';
import Calendar from 'primevue/calendar';
import useOrder from '@/modules/useOrder';
import { generalStore } from '@/store';
import { DateRange } from '@/models/Interfaces';
import useProduct from '@/modules/useProduct';
import { BusinessAccountingFilter } from '@/models/BusinessClients';
import { DocumentTypeForFilter } from '@/models/Enums';
import { IdName } from '@/models/Interfaces';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    props: {
        clientId: {
            type: Number,
            required: true
        }
    },
    components: {
        InputNumber,
        Multiselect,
        Calendar
    },
    name: 'businessAccountingFilter',
    beforeRouteEnter(to, from, next) {
        to.params.return = 'businessClientView';
        if (from.name == 'businessClientEdit') {
            to.params.return = 'businessClientEdit';
        }
        next();
    },
    async setup(props) {
        const returnName = ref('businessClientView');
        const route = useRoute();
        const { t } = useI18n();
        const calendar = ref();
        const usersForFilter = ref<IdName[]>([]);
        const filter = ref<BusinessAccountingFilter>(new BusinessAccountingFilter(props.clientId));
        const filterCopy: BusinessAccountingFilter = generalStore.getters.getBusinessAccountingFilter;
        filter.value = JSON.parse(JSON.stringify(filterCopy));
        if (!filter.value) {
            filter.value = new BusinessAccountingFilter(props.clientId);
        }
        const { dateRangeValidate } = useProduct();
        const documentTypes = [];
        for (const [propertyKey, propertyValue] of Object.entries(DocumentTypeForFilter)) {
            if (!Number.isNaN(Number(propertyKey))) {
                continue;
            }
            documentTypes.push({ id: propertyValue, name: t('documentTypes.' + propertyKey) });
        }

        const { convertDate } = useOrder();
        const dateRange = reactive(new DateRange());
        if (filter.value?.createDateStart) {
            dateRange.fromDate = new Date(filter.value?.createDateStart);
        }
        if (filter.value?.createDateEnd) {
            dateRange.toDate = new Date(filter.value?.createDateEnd);
        }

        function ResetFilter() {
            generalStore.commit('saveBusinessAccountingFilter', new BusinessAccountingFilter(props.clientId));
            router.push({ name: returnName.value, params: { id: props.clientId, page: 3 } });
        }

        function Close() {
            let oldFilter = generalStore.getters.getBusinessAccountingFilter;
            if (!oldFilter) {
                oldFilter = new BusinessAccountingFilter(props.clientId);
            }
            oldFilter.pageIndex = 0;
            generalStore.commit('saveBusinessAccountingFilter', oldFilter);
            router.push({ name: returnName.value, params: { id: props.clientId, page: 3 } });
        }

        function Search() {
            if (filter.value) {
                if (dateRange.fromDate) {
                    filter.value.createDateStart = convertDate(dateRange.fromDate);
                }
                if (dateRange.toDate) {
                    filter.value.createDateEnd = convertDate(dateRange.toDate);
                }

                filter.value.keyword = '';
                filter.value.pageIndex = 0;
            }

            generalStore.commit('saveBusinessAccountingFilter', filter.value);
            router.push({ name: returnName.value, params: { id: props.clientId, page: 3 } });
        }

        const dateChanged = () => {
            if (!dateRange.fromDate || !dateRange.toDate) {
                return;
            }
            dateRangeValidate(dateRange);
        };
        async function onMountedAction() {
            usersForFilter.value = await generalStore.dispatch('loadProviderUsers');
            returnName.value = route.params.return.toString();
        }
        onMounted(onMountedAction);

        return {
            filter,
            usersForFilter,
            dateRange,
            ResetFilter,
            Close,
            Search,
            calendar,
            dateChanged,
            documentTypes
        };
    }
});
